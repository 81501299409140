<template>
  <div v-if="!requireContext || context " class="d-flex flex-wrap px-4">
    <div class="breadcrumbs d-flex pa-0 col-12 content-full-h">
      <div>
        <v-btn
          class="mt-3 mb-4 mr-2"
          fab
          dark
          x-small
          color="primary"
          @click="changeSideBar()"
        >
          <v-icon dark>
            {{ !showFacets ? "mdi-forwardburger" : "mdi-backburger" }}
          </v-icon>
        </v-btn>
        <v-expand-x-transition>
          <div v-show="showFacets" class="sidebar">
            <Facets :facets-values="facetValues" :facets-config="facetsConfig" :column-height="facetbarHeight - 0" @filtersUpdate="onFiltersUpdate" />
          </div>
        </v-expand-x-transition>
      </div>
      <v-card class="d-flex justify-center flex-column pa-3 ma-4 grow text-center">
        <div>Participants</div>
      </v-card>
      <div class="d-flex flex-column grow">
        <v-card class="d-flex justify-center flex-column pa-3 my-4 text-center">
          <div>Nº Selected Participants</div>
        </v-card>
        <v-card class="d-flex justify-center flex-column pa-3 mb-4 grow text-center">
          <div>Message</div>
        </v-card>
      </div>
    </div>
  </div>
  <div v-else>
    <v-card class="ma-6" color="warning" rounded="lg" outlined elevation="4">
      <v-card-text class="white--text text-h6">
        <div>
          Un contexte doit être sélectionné.
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
// import { format } from 'date-fns';
import Facets from '../components/Facets';
import { getTargetingConfig } from '@/configs/helper';
// import { requests } from '@/mixins/requests';
import store from '@/store/index';
// import transformFilters from '@/utils/facets';

const config = require('@/configs/targetingFacets.json');

console.log('store.getters.getActiveBrand :', store.getters.getActiveBrand);
const workspace = computed(() => store.getters.getActiveBrand);

console.log('store.getters.getActiveContext :', store.getters.getActiveContext);
const context = computed(() => store.getters.getActiveContext);

const requireContext = computed(() => !!(config[workspace.value].requireContext));

const facetsConfig = computed(() => (requireContext.value ? getTargetingConfig(context.value) : getTargetingConfig(workspace.value)));

const filters = ref([]);

const showFacets = ref(true);

const facetValues = ref({});

const facetbarHeight = 285;

const changeSideBar = () => showFacets.value = !showFacets.value;

const configureFacetValues = () => {
  const values = {};
  Object.entries(facetsConfig.value).forEach(([name, facet]) => {
    if (facet.aggregated) {
      values[name] = { data: [] };
    }
    if (facet.values) {
      values[name] = { data: facet.values };
    }
  });
  facetValues.value = values;
};

const onFiltersUpdate = value => {
  filters.value = value;
};

onMounted(async () => {
  if (!requireContext.value || context.value) {
    configureFacetValues();
  }
});

</script>

<style lang="scss">
.breadcrumbs {
  min-height: 40px;
}
.sidebar {
  width: 330px;
  height: calc(100vh - 185px);
  overflow-x: hidden;
  overflow-y: scroll;
}
.content {
  width: calc(100% - 330px);
  height: calc(100vh - 140px);
}
.results {
  max-height: calc(100vh - 275px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.content-full-h {
  height: calc(100vh - 112px);
}

</style>
