import { createLocalJWKSet, jwtVerify } from 'jose';

const encodeBase64 = data => {
  if (typeof btoa === 'function') {
    return btoa(data);
  } if (typeof Buffer === 'function') {
    return Buffer.from(data, 'utf-8').toString('base64');
  }
  throw new Error('Failed to determine the platform specific encoder');
};

// // Cross browser / node compatibility for b64
const decodeBase64 = data => {
  if (typeof atob === 'function') {
    return atob(data);
  } if (typeof Buffer === 'function') {
    return Buffer.from(data, 'base64').toString('utf-8');
  }
  throw new Error('Failed to determine the platform specific decoder');
};

const getPublicJWKSet = VUE_APP_SSO_PUBLIC_JWK_SET => VUE_APP_SSO_PUBLIC_JWK_SET && JSON.parse(decodeBase64(VUE_APP_SSO_PUBLIC_JWK_SET, 'base64').toString());

const getSSOLink = (VUE_APP_SSO_URL, VUE_APP_SSO_CLIENT_ID, redirect_uri, state) => `${VUE_APP_SSO_URL}/login?client_id=${VUE_APP_SSO_CLIENT_ID}&redirect_uri=${redirect_uri}/authorize${state ? `&state=${state}` : ''}`;

const getUser = async (token, VUE_APP_SSO_PUBLIC_JWK_SET) => {
  const publicJWK = getPublicJWKSet(VUE_APP_SSO_PUBLIC_JWK_SET);

  const jwks = createLocalJWKSet(publicJWK);

  const data = await jwtVerify(token, jwks).then(d => d.payload).catch(() => null);

  return data;
};

const isAuthenticated = async (token, VUE_APP_SSO_PUBLIC_JWK_SET) => {
  if (!token) {
    return null;
  }

  const user = await getUser(token, VUE_APP_SSO_PUBLIC_JWK_SET);

  if (!!(user) && (user.roles.includes('admin') || user.roles.includes('target'))) {
    return user.writeWorkspaces;
  }
  return null;
};

export { getSSOLink, getUser, isAuthenticated, encodeBase64, decodeBase64 };
