const labels = {
  no: 'Non',
  yes: 'Oui',
  cancel: 'Annuler',
  save: 'Enregistrer',
  delete: 'Effacer'
};

const getLabel = key => (labels[key] ? labels[key] : key);

export default getLabel;
